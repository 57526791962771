import axios from "axios";
import dayjs from "dayjs";

import {
  prepareTradesFilters,
  formatCurrencyCasing,
  getUniqueListBy,
} from "@/utils/helpers";
import { formatTradesForTable } from "@/utils/formatters";
import client from "@/utils/axios";

let cancel;

export default {
  namespaced: true,

  state: {
    trades: [],
    newTrades: [],
    filters: {},
    meta: {
      exchanges: [],
      instruments: [],
    },
  },

  actions: {
    async fetch({ commit }, filters = {}) {
      const params = prepareTradesFilters(filters.parameters, filters.side);

      commit("setFilters", filters);

      if (cancel) {
        cancel.cancel();
      }

      cancel = axios.CancelToken.source();

      if (filters.clear) {
        commit("setTrades", []);
      }

      try {
        const { data } = await client.get("/api/v2/my/trades", {
          params,
          cancelToken: cancel.token,
        });

        commit("setTrades", data);
      } catch (e) {
        console.log("Failed to fetch trades", e);
      }
    },

    async fetchLatest({ commit }, filters = {}) {
      if (cancel) {
        cancel.cancel();
      }

      cancel = axios.CancelToken.source();

      const { parameters: params } = filters;
      
      try {
        const { data } = await client.get("/api/my/latest-trades", {
          params,
          cancelToken: cancel.token,
        });

        commit("setTrades", data);
      } catch (e) {
        console.log("Failed to fetch latest trades", e);
      }
    },

    async fetchMeta({ commit, rootGetters }) {
      try {
        const { data } = await client.get("/api/v2/my/trades/meta");

        commit("setMeta", {
          data,
          getExchangeFullName: rootGetters.getExchangeFullName,
        });
      } catch (e) {
        console.log("Failed to fetch trades meta", e);
      }
    },

    async add({ state, commit, rootState }, trades) {
      if (rootState.route?.name === "portfolio") {
        return;
      }
      commit("setNewTrades", [...trades, ...state.newTrades]);
    },
  },

  getters: {
    trades(state, _, __, rootGetters) {
      const {
        filters: { parameters },
        newTrades,
      } = state;

      const filteredNewTrades = newTrades.filter(
        ({ timestamp, exchangeId, currency }) => {
          let result = true;

          if (parameters?.dateRange) {
            const { start, end } = parameters.dateRange;
            result = dayjs(timestamp).isBetween(
              dayjs(start),
              dayjs(end),
              "day",
              "[]"
            );
          }

          if (parameters?.exchanges?.length) {
            result =
              result &&
              exchangeId &&
              parameters?.exchanges?.includes(exchangeId);
          }

          if (parameters?.instruments?.length) {
            result = result && parameters.instruments.includes(currency);
          }

          return result;
        }
      );

      let formattedTrades = [...filteredNewTrades, ...state.trades].map(
        (trade) => {
          const baseCurrencyFormatted = formatCurrencyCasing(
            trade.baseCurrency
          );
          const quoteCurrencyFormatted = formatCurrencyCasing(
            trade.quoteCurrency
          );
          const feeCurrencyFormatted = formatCurrencyCasing(trade.feeCurrency);

          const baseKey = `${trade.exchangeId}:${baseCurrencyFormatted}`;
          const quoteKey = `${trade.exchangeId}:${quoteCurrencyFormatted}`;
          const feeKey = `${trade.exchangeId}:${feeCurrencyFormatted}`;
          const instrument =
            trade.contractType !== "spot"
              ? trade.symbol
              : `${baseCurrencyFormatted}/${quoteCurrencyFormatted}`;
          const { name: apiKeyName } =
            rootGetters["portfolio/getApiKey"](trade.apiKeyId) ?? {};
          const finalFee = feeCurrencyFormatted
            ? feeCurrencyFormatted.replace(/F0/g, "")
            : feeCurrencyFormatted;

          return {
            ...trade,
            baseCurrency:
              rootGetters.currencyToAliasMap[baseKey] ?? baseCurrencyFormatted,
            quoteCurrency:
              rootGetters.currencyToAliasMap[quoteKey] ??
              quoteCurrencyFormatted,
            feeCurrency: rootGetters.currencyToAliasMap[feeKey] ?? finalFee,
            instrument: instrument ? instrument.replace(/F0/g, "") : instrument,
            apiKeyName,
            exchangeFullName: rootGetters.getExchangeFullName(trade.exchangeId),
          };
        }
      );

      if (formattedTrades.length > 2000) {
        formattedTrades = formattedTrades.slice(0, 2000);
      }

      return formatTradesForTable(formattedTrades);
    },
    latest10Trades(_, getters) {
      const { trades } = getters;
      return trades.slice(0, 10);
    }
  },

  mutations: {
    setTrades(state, data) {
      state.trades = data;
    },

    setNewTrades(state, data) {
      state.newTrades = data;
    },

    setFilters(state, data) {
      state.filters = data;
    },

    setMeta(state, { data, getExchangeFullName }) {
      state.meta.exchanges = getUniqueListBy(
        data.exchanges
          .reduce((memo, current) => {
            if (!current?.toLowerCase()?.includes("_oms")) {
              memo.push({
                label: getExchangeFullName(current),
                exchangeId: current,
              });
            }
            return memo;
          }, [])
          .sort((a, b) => a?.label?.localeCompare(b?.label)),
        "exchangeId"
      );

      state.meta.instruments = getUniqueListBy(
        data.instruments
          .map((instrument) => {
            return {
              text: instrument?.replace(/F0/g, ""),
              value: instrument?.replace(/F0/g, ""),
            };
          })
          .sort((a, b) => a?.text?.localeCompare(b?.text)),
        "value"
      );
    },
  },
};
