import cloneDeep from "lodash/cloneDeep";
import axios from "axios";

import { formatTransactionsForTable } from "@/utils/formatters";
import { getUniqueListBy } from "@/utils/helpers";
import client from "@/utils/axios";

let cancel;

export default {
  namespaced: true,

  state: {
    transactions: [],
  },

  actions: {
    async fetch({ commit }, { parameters, clear } = {}) {
      if (cancel) {
        cancel.cancel();
      }

      cancel = axios.CancelToken.source();

      if (clear) {
        commit("setTransactions", []);
      }

      try {
        const { data } = await client.get(`/api/v2/my/transactions`, {
          params: parameters,
          cancelToken: cancel.token,
        });

        commit("setTransactions", data);
      } catch (e) {
        console.log("Failed to fetch transactions", e);
      }
    },

    async update({ state, commit }, newTransactions) {
      const cloned = cloneDeep(state.transactions);

      if (!cloned) {
        return;
      }

      for (let newTransaction of newTransactions) {
        const transactionToUpdate = cloned.find(
          (transaction) => transaction.internalId === newTransaction.internalId
        );

        if (!transactionToUpdate) {
          continue;
        }

        const transactionToUpdateIndex = cloned.indexOf(transactionToUpdate);

        for (let key in transactionToUpdate) {
          cloned[transactionToUpdateIndex][key] = newTransaction[key];
        }

        cloned[transactionToUpdateIndex].txId = newTransaction.txId;
      }

      commit("setTransactions", cloned);
    },

    async add({ state, commit }, newTransactions) {
      let all = getUniqueListBy(
        [...newTransactions, ...state.transactions],
        "id"
      );

      if (all.length > 2000) {
        all = all.slice(0, all.length - newTransactions.length);
      }

      commit("setTransactions", all);
    },
  },

  getters: {
    transactions(state) {
      return formatTransactionsForTable(state.transactions);
    },
    latest10Transactions(_, getters) {
      const { transactions } = getters;
      return transactions.slice(0, 10);
    }
  },

  mutations: {
    setTransactions(state, data) {
      state.transactions = data;
    },
  },
};
